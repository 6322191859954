import { CartState, SwellPoints, UpdateQtyManualData, UpdateTotal } from "./";
import { IShopId } from "interfaces/products.interfaces";
import { ICartList } from "interfaces/index";

type CartActionType =
	| { type: "[Cart] - Remove shopId" }
	| { type: "[Cart] - Add shopId"; payload: IShopId }
	| { type: "[Cart] - Clear all cartList" }
	| { type: "[Cart] - Add to cart"; payload: ICartList }
	| { type: "[Cart] - Remove from cart"; payload: string | number }
	| { type: "[Cart] - Update quantity on cart add"; payload: string | number }
	| { type: "[Cart] - Update cart list"; payload: ICartList }
	| {
			type: "[Cart] - Update quantity on cart add manual";
			payload: UpdateQtyManualData;
	  }
	| {
			type: "[Cart] - Update quantity on item cart removed";
			payload: string | number;
	  }
	| { type: "[Cart] - Set deal"; payload: any }
	| { type: "[Cart] - Remove deal"; payload: string | number }
	| { type: "[Cart] - Update full cart"; payload: ICartList[] }
	| { type: "[Cart] - Update cart list has deal" }
	| { type: "[Cart] - Update cart total"; payload: UpdateTotal }
	| { type: "[Cart] - Set swell points"; payload: SwellPoints }
	| { type: "[Cart] - Reset cart" };

export const CartReducer = (
	state: CartState,
	action: CartActionType
): CartState => {
	switch (action.type) {
		case "[Cart] - Clear all cartList":
			return { ...state, cart: [] };
		case "[Cart] - Add to cart":
			return { ...state, cart: [action.payload, ...state.cart] };
		case "[Cart] - Add shopId":
			return {
				...state,
				shopId: action.payload.id,
			};
		case "[Cart] - Remove shopId":
			return {
				...state,
				shopId: null,
			};
		case "[Cart] - Update cart list":
			return {
				...state,
				cart: state?.cart.map((item) =>
					item?.id === action?.payload?.id &&
					item.hasOwnProperty("deal") !== true
						? action?.payload
						: item
				),
			};
		case "[Cart] - Remove from cart":
			return {
				...state,
				cart: state.cart.filter(
					(item) => item.cartItemId !== action.payload
				),
			};
		case "[Cart] - Update quantity on cart add":
			return {
				...state,
				cart: state.cart.map((item) =>
					item.cartItemId === action.payload
						? { ...item, quantity: (item.quantity += 1) }
						: item
				),
			};
		case "[Cart] - Update quantity on cart add manual":
			return {
				...state,
				cart: state.cart.map((item) =>
					item.cartItemId === action.payload.cartItemId
						? { ...item, quantity: action.payload.quantity }
						: item
				),
			};
		case "[Cart] - Update quantity on item cart removed":
			return {
				...state,
				cart: state.cart.map((item) =>
					item.cartItemId === action.payload
						? { ...item, quantity: item.quantity - 1 }
						: item
				),
			};
		case "[Cart] - Set deal":
			return {
				...state,
				deal: action.payload.deal,
			};
		case "[Cart] - Remove deal":
			return {
				...state,
				deal: state.deal.filter((item) => item.deal !== action.payload),
			};
		case "[Cart] - Update full cart":
			return { ...state, cart: action.payload };
		case "[Cart] - Update cart list has deal":
			return {
				...state,
				cart: state.cart.filter((item) => !item.deal),
			};
		case "[Cart] - Update cart total":
			return {
				...state,
				total: action.payload.total,
			};
		case "[Cart] - Set swell points":
			return { ...state, totalPoints: action.payload.points };
		case "[Cart] - Reset cart":
			return {
				...state,
				cart: [],
			};

		default:
			return state;
	}
};
