import React from "react";
import App from "next/app";
import { Analytics } from "@vercel/analytics/react";
import "../styles/globals.scss";
import TopNav from "components/TopNav/TopNav";
import Navbar from "components/Navbar/Navbar";

import Footer from "components/Footer/Footer";
import { AppProps } from "next/app";
import * as contentful from "contentful";
import { GlobalProvider } from "context/global/GlobalProvider";
import Script from "next/script";

import Notifications from "@/components/Notifications/Notifications";

import { OrderGrooveProvider } from "context/ordergroove";
import { NotificationProvider } from "context/notification/NotificationProvider";
import GeoRedirectBar from "@/components/GeoRedirectBar/GeoRedirectBar";
import { UiProvider } from "context/ui";
import { CartProvider } from "context/cart";
import { AccountProvider } from "context/account/AccountProvider";
import { YotpoProvider } from "context/yotpo";
import { IProduct } from "@/types/products.interfaces";
import shopifyCall from "@/services/shopifyClient";

interface AppCustomProps extends AppProps {
	header: any;
	footerContent: any;
	pageProps: any;
	styles: any;
	allProducts: IProduct[];
	menu: {
		id: string;
		items: Array<{ id: string; title: string; url: string }>;
	};
}
function MyApp({
	Component,
	pageProps,
	header,
	footerContent,
	menu,
}: AppCustomProps): JSX.Element {
	const NewComponent: any = Component;
	return (
		<>
			<NotificationProvider>
				{/* sundaysky */}
				<Script
					type="text/javascript"
					src="//cdn1-res.sundaysky.com/vop/v2/t.js"
					async={true}
				/>
				{/* Google Tag Manager */}
				<Script
					type="text/javascript"
					async={true}
					src="https://cdn.swellrewards.com/loader/rjEHWea5FGGt26sJDJsnUw.js"
				/>
				<Script
					id="text/javascript"
					src="https://static.ordergroove.com/6e14b0000cc411eba9cdbc764e10b970/main.js"
				/>
				<Script
					id="gorgias-chat-widget-install-v2"
					src="https://config.gorgias.chat/gorgias-chat-bundle-loader.js?applicationId=20295"
				/>
				<Script
					type="text/javascript"
					async={true}
					id="get-roster"
					src="https://sa.getroster.com/sa.js?token=q1j5vkj2mzawedkrotjjddfpwnluzz09JUr97hR7j0FFeoihb9Aap0jnjbtWNe&cookies=true&domain=redcon1.com"
				/>
				<React.StrictMode>
					<AccountProvider>
						<OrderGrooveProvider>
							<CartProvider>
								<GlobalProvider>
									<UiProvider>
										<YotpoProvider>
											<div className="main-header">
												<GeoRedirectBar />
												<TopNav topNavData={header} />
												<Navbar
													{...header.fields}
													menu={menu}
												/>
											</div>
											<main className="main-content">
												{pageProps?.product
													?.productPageMessage
													?.productPageMessage ? (
													<div className="productPageMessage">
														{
															pageProps?.product
																?.productPageMessage
																?.productPageMessage
														}
													</div>
												) : null}
												<NewComponent {...pageProps} />
											</main>
										</YotpoProvider>
									</UiProvider>
								</GlobalProvider>
							</CartProvider>
							<Footer content={footerContent} />
						</OrderGrooveProvider>
					</AccountProvider>
				</React.StrictMode>
				<Notifications />
			</NotificationProvider>
			<Analytics />
		</>
	);
}

MyApp.getInitialProps = async (ctx) => {
	const appProps = await App.getInitialProps(ctx);
	let header: any = null;
	let footer: any = null;

	// const shopifyClient = createStorefrontApiClient({
	// 	storeDomain: "http://redcon1.myshopify.com",
	// 	apiVersion: "2024-01",
	// 	publicAccessToken: "4090e423ac794711ea473ec962fae179",
	// });

	const client = contentful.createClient({
		space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE,
		environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
		accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
	});

	try {
		footer = await client.getEntry("7xSoXji1ohEomUuF42DoaG", {
			include: 10,
		});
		header = await client.getEntry("59WvzLkXjtlOLFFkdeuVY5", {
			include: 10,
		});

		const query = {
			query: `#graphql
			query menu {
				menu(handle:"main-menu") {
					id
					items {
						id
						title
						url
					}
				}
			}
		`,
		};

		const { data, errors } = await shopifyCall(query);

		if (errors)
			throw new Error(
				`Error fetching menu - ${
					errors.graphQLErrors[0]?.message || "Unknown error"
				}`
			);

		const { menu } = data;

		return {
			...appProps,
			header: header,
			menu,
			footerContent: footer,
		};
	} catch (error) {
		if (error instanceof Error) {
			throw new Error(`${error.message}`);
		}
		return { props: {} };
	}
};

export default MyApp;
