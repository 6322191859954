import Link from "next/link";
import styles from "./RightNavigation.module.scss";
import CartRightNavigation from "./CartRightNavigation";
import SearchSvg from "public/search.svg";
import { AccountContext } from "context/account/AccountContext";
import { useContext } from "react";

interface RightNavigationProps {
	cornerTextLinks: any[];
}

const RightNavigation = ({
	cornerTextLinks,
}: RightNavigationProps): JSX.Element => {
	const { user } = useContext(AccountContext);

	!user
		? cornerTextLinks
		: (cornerTextLinks = cornerTextLinks.map((fields) => {
				if (fields.label === "Login") {
					return {
						...fields,
						title: "Account",
					};
				}
				return fields;
		  }));

	return (
		<ul className={styles.rightNavigation}>
			<li>
				<Link href="/search">
					<a aria-label="search" className={styles.search}>
						<SearchSvg width={22} height={22} />
					</a>
				</Link>
			</li>
			<li>
				<CartRightNavigation />
			</li>
			{cornerTextLinks &&
				cornerTextLinks.map(({ fields }) => (
					<li className="d-none d-md-block" key={fields.title}>
						<Link prefetch={false} href={fields.destinationUrl}>
							<a aria-label={fields.title}>{fields.title}</a>
						</Link>
					</li>
				))}
		</ul>
	);
};

export default RightNavigation;
