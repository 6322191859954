/* eslint-disable no-unused-vars */
import { createContext } from "react";
import { ICartList, ITierOperator } from "interfaces";
import { UpdateQtyManualData } from "./";

interface contextProps {
	cart: ICartList[];
	deal: any[];
	tierOperator: ITierOperator[];
	shopId: string | null;
	total: number;
	totalPoints: number;
	// method
	addShopId: (id: string) => void;
	addToCart: (productCart: ICartList) => void;
	removeDeal: (deal: string | number) => void;
	removeFromCart: (cartItemId: string | number) => void;
	updateFullCart: (cart: ICartList[], total?: number) => void;
	updateCartList: (productCart: ICartList) => void;
	updateCartListHasDeal: () => void;
	updateCartTotal: (total: number) => void;
	updateQtyOnCartRemove: (cartItemId: string | number) => void;
	updateQtyOnCartAddManual: (payload: UpdateQtyManualData) => void;
	setDealOnState: (deals: any, total: number, cart: ICartList[]) => void;
	setSwellPoints: (points: number) => void;
	resetCart: () => void;
}

export const CartContext = createContext({} as contextProps);
