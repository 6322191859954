import { FunctionComponent, useState, useEffect } from "react";
import { IButton, IImageLinks } from "../../interfaces";
import AccountMenu from "../AccountMenu/AccountMenu";
import Brand from "../Brand/Brand";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import MainNavigation from "../MainNavigation/MainNavigation";
import MenuList from "../MenuList/MenuList";
import RightNavigation from "../RightNavigation/RightNavigation";
import Search from "../Search/Search";
import UserMenu from "../UserMenu/UserMenu";
import styles from "./Navbar.module.scss";
import Cookies from "js-cookie";

interface Props {
	cornerTextLinks: Array<IButton>;
	sideBarAccountLinks: Array<IImageLinks>;
	sideBarLinkLists: Array<any>;
	siteLogo: any;
	altText: string;
	menu: {
		id: string;
		items: Array<{ id: string; title: string; url: string }>;
	};
}

const Navbar: FunctionComponent<Props> = ({
	sideBarAccountLinks,
	sideBarLinkLists,
	cornerTextLinks,
	siteLogo,
	menu,
}: Props) => {
	let userData: any = Cookies.get("userData");
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true);

		return () => setMounted(false);
	}, []);

	if (!mounted) return null;

	return (
		<>
			<nav data-cy="nav" className={`${styles.nav}`}>
				<ul className={styles.leftNavigation}>
					<div className={styles.leftNavigationContainer}>
						<li>
							<BurgerMenu>
								<Search />
								<UserMenu />
								{userData && (
									<AccountMenu data={sideBarAccountLinks} />
								)}
								<MenuList data={sideBarLinkLists} />
							</BurgerMenu>
						</li>
						<li>
							<Brand logo={siteLogo} altText={siteLogo} />
						</li>
					</div>
				</ul>
				<div>
					<MainNavigation menu={menu} />
				</div>
				<div style={{ overflow: "auto" }}>
					<RightNavigation cornerTextLinks={cornerTextLinks} />
				</div>
			</nav>
		</>
	);
};

export default Navbar;
