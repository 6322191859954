import Link from "next/link";
import styleComponent from "./ImageLink.module.scss";
import RC1Image from "@/components/RC1Image/RC1Image";

interface ImageLink {
	label: string;
	link: string;
	source: string;
}

const data: Array<ImageLink> = [
	{
		label: "News",
		link: "https://global.redcon1.com/",
		source: "https://cdn.shopify.com/s/files/1/1304/0433/files/news.png?v=1707752078",
	},
	{
		label: "Tier Operator Portal",
		link: "https://accounts.redcon1.com/account/login?view=tier-op-login",
		source: "https://cdn.shopify.com/s/files/1/1304/0433/files/Tier-Operator.png?v=1707752078",
	},
	{
		label: "REDCON1 Foundation",
		link: "https://redcon1foundation.com/",
		source: "https://cdn.shopify.com/s/files/1/1304/0433/files/RC1_Foundation_Header_Logo.svg?v=1707752078",
	},
	{
		label: "Instagram",
		link: "https://www.instagram.com/redcon1/",
		source: "https://cdn.shopify.com/s/files/1/1304/0433/files/instagram.png?v=1707752078",
	},
	{
		label: "Facebook",
		link: "https://www.facebook.com/groups/Redcon1customers/",
		source: "https://cdn.shopify.com/s/files/1/1304/0433/files/facebook.png?v=1707752078",
	},
	{
		label: "Business to Business Portal",
		link: "https://global.redcon1.com/vendor/",
		source: "https://cdn.shopify.com/s/files/1/1304/0433/files/gym.png?v=1707752079",
	},
];

const ImagesLink = (): JSX.Element => {
	return (
		<ul className={styleComponent.imageLink}>
			{data &&
				data.map((image) => (
					<li key={image.label}>
						<Link prefetch={false} href={image.link}>
							<a rel="me">
								<RC1Image
									src={image.source}
									alt={image.label}
									width={35}
									height={25}
								/>
							</a>
						</Link>
					</li>
				))}
		</ul>
	);
};

export default ImagesLink;
