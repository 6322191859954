import Link from "next/link";
import styles from "./MainNavigation.module.scss";
import { useRouter } from "next/router";

interface MainNavigationProps {
	menu: Menu;
}

interface Menu {
	id: string;
	items: Array<Item>;
}

interface Item {
	id: string;
	title: string;
	url: string;
}

const formatLinks = (menu: Menu) => {
	if (!menu) return;

	const isLocalOrVercel =
		process.env.NODE_ENV === "development" ||
		process.env.VERCEL_ENV !== "production";
	if (!isLocalOrVercel) return menu;

	const newMenu = { ...menu };
	newMenu.items = newMenu.items.map((item) => {
		if (item.url.includes("https://global.redcon1.com")) return item;

		const newItem = { ...item };
		newItem.url = newItem.url.replace("https://redcon1.com", "");
		return newItem;
	});
	return newMenu;
};

const normalizePath = (path) => {
	let normalizedPath = path.split(/[?#]/)[0];
	if (normalizedPath.length > 1) {
		normalizedPath = normalizedPath.replace(/\/$/, "");
	}
	return normalizedPath;
};

const MainNavigation = ({ menu }: MainNavigationProps): JSX.Element => {
	const formattedLinks = formatLinks(menu);
	const router = useRouter();
	const isActiveLink = (linkPath) => {
		const currentRoutePath = normalizePath(router.asPath);
		const normalizedLinkPath = normalizePath(linkPath);
		return normalizedLinkPath === currentRoutePath;
	};

	return (
		<>
			{formattedLinks && (
				<ul className={styles.mainNavigation}>
					{formattedLinks.items.map((item) => (
						<li key={item.title}>
							<Link prefetch={false} href={item.url}>
								<a
									className={
										isActiveLink(item.url)
											? styles.active
											: ""
									}
									rel="canonical"
								>
									{item.title}
								</a>
							</Link>
						</li>
					))}
				</ul>
			)}
		</>
	);
};

export default MainNavigation;
