import fetch from "node-fetch";

const accessToken = "b5f37fd8167058ff260b2b4a18d78f67";
const store = "redcon1";
const hostName = store + ".myshopify.com";
const apiVersion = "2023-10";
const shopGraphQl =
	"https://" + hostName + "/api/" + apiVersion + "/graphql.json";
const url = shopGraphQl;

const shopifyCall = async (body) => {
	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"X-Shopify-Storefront-Access-Token": accessToken,
		},
		body: JSON.stringify(body),
	});
	const data = await response.json();
	return data;
};

export default shopifyCall;
